import React from "react"
import PortableText from "@sanity/block-content-to-react"
import {graphql, Link} from "gatsby"
import {Layout, ModalLayout} from "../components/layout"
import {H2, P} from "../components/Typography"
import styled from "styled-components"
import Seo from "../components/seo"
import * as Sections from "../components/SectionElements"
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing-3'
import * as Serializer from "../components/Serializer"

const NavigationButtons = styled.div`
  width: 100%;
  position: fixed;
  top: 0;

  @media ${props => props.theme.breakpoints.desktop} {
    max-width: 900px;
    width: 100%;
    right: auto;
    left: 50%;
    transform: translateX(-50%);
    margin: 0 auto;
  }
`
const CloseButton = styled.button`
  position: absolute;
  right: ${props => props.theme.spacings.xSmall};
  top: ${props => props.theme.spacings.xxSmall};
  background-color: transparent;
  font-size: 40px;
  font-weight: 200;
  line-height: 0.5;

  > a {
    padding: 0px;
    border-radius: 0;
    background-color: transparent;
    color: ${props => props.color ? props.color : props.theme.colors.secondary};
  }

  @media ${props => props.theme.breakpoints.desktop} {
    // right: ${props => props.theme.spacings.section};
    font-size: 60px;
  }
`

const CoursWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  row-gap: ${props => props.spacing ? props.spacing : props.theme.spacings.xxSmall};
  padding-bottom: ${props => props.spacing ? props.spacing : props.theme.spacings.small};
`
const CoursTitle = styled(P)`
  display: inline;
  > span {
    background-color: ${props => props.color ? props.color : props.theme.colors.grey1};
    padding: 2px 5px;
    border-radius: 5px;
    box-decoration-break: clone;
    color: ${props => props.color ? props.color : props.theme.colors.foreground};
    margin-right: ${props => props.spacing ? props.spacing : props.theme.spacings.xxxSmall};

    :hover {
      background-color: ${props => props.color ? props.color : props.theme.colors.flash};
    }
  }
`
const NbHours = styled(P)`
  display: inline;
  > span {
    background-color: ${props => props.color ? props.color : props.theme.colors.secondary};
    padding: 2px 5px;
    border-radius: ${props => props.spacing ? props.spacing : props.theme.spacings.small};
    box-decoration-break: clone;
    color: ${props => props.color ? props.color : props.theme.colors.background};
    margin-right: ${props => props.spacing ? props.spacing : props.theme.spacings.xxxSmall};
  }
`
const Lecturer = styled(P)`
  display: inline;
  margin-top:-.15em;
  > span {
    line-height: 1.7;
    // background-color: ${props => props.color ? props.color : props.theme.colors.grey1};
    padding: 2px 5px;
    border-radius: 5px;
    border: 1px solid ${props => props.theme.colors.grey1};
    box-decoration-break: clone;
    color: ${props => props.color ? props.color : props.theme.colors.secondary};
    margin-right: ${props => props.spacing ? props.spacing : props.theme.spacings.xxxSmall};
  }
`
const TextBlock = styled(PortableText)`
  >p:last-child {
    padding-bottom: 0 !important;
  }
`

const Module = ({data}) => {
    const Node = data.allSanityModule.edges[0].node

    return (
    <ModalRoutingContext.Consumer>
        {({ modal, closeTo }) => (
          <>
              {modal ? (
                <>
                <Seo title={Node.title}/>
                <NavigationButtons>
                  <CloseButton><Link to={closeTo} state={{noScroll: true}}>&#215;</Link></CloseButton>
                </NavigationButtons>
                <ModalLayout>
                  <Sections.CenteredSection>
                    <H2>{Node.title}</H2>
                    <TextBlock
                      blocks={Node._rawModuletext}
                      serializers = {Serializer.block}
                    />
                  </Sections.CenteredSection>
                  <Sections.FullWidthSection>
                  {Node.lecture.map((cours, i) => (
                    <CoursWrapper key={i}>
                      <CoursTitle context="nonblock"><span><Link to={cours.slug.current} state={{modal: true}}>{cours.title}</Link></span></CoursTitle>
                      <NbHours context="nonblock"><span>{cours.nbHours}h</span></NbHours>
                      {cours.lecturer.map((prof, j) => (
                        <Lecturer context="nonblock" key={j}><span>{prof.firstname} {prof.surname}</span></Lecturer>
                      ))}
                    </CoursWrapper>
                  ))}
                  </Sections.FullWidthSection>
                </ModalLayout>
                </>
              ) : (
                <>
                <Seo title={Node.title}/>
                <Layout homebutton={true}>
                  <Sections.CenteredSection>
                    <H2>{Node.title}</H2>
                    <TextBlock
                      blocks={Node._rawModuletext}
                      serializers = {Serializer.block}
                    />
                  </Sections.CenteredSection>
                  <Sections.FullWidthSection>
                  {Node.lecture.map((cours, i) => (
                    <CoursWrapper key={i}>
                      <CoursTitle context="nonblock"><span><Link to={cours.slug.current}>{cours.title}</Link></span></CoursTitle>
                      <NbHours context="nonblock"><span>{cours.nbHours}h</span></NbHours>
                      {cours.lecturer.map((prof, j) => (
                        <Lecturer context="nonblock" key={j}><span>{prof.firstname} {prof.surname}</span></Lecturer>
                      ))}
                    </CoursWrapper>
                  ))}
                  </Sections.FullWidthSection>
                </Layout>
                </>
              )}
          </>
        )}
    </ModalRoutingContext.Consumer>
    )
};

export default Module

export const query = graphql`
query SinglePostQuery($moduleId: String!) {
  allSanityModule(filter: {_id: { eq: $moduleId }}) {
    edges {
      node {
        slug {
          current
        }
        title
        id
        _rawModuletext
        lecture {
          slug {
            current
          }
          title
          lecturer {
            firstname
            surname
          }
          nbHours
        }
      }
    }
  }
}
`;